import { TDensity } from './helpers/createDensityStore/createDensityStore'

export const ROW_HEIGHT: Record<TDensity, number> = { default: 48, compact: 40 }
export const HEADER_HEIGHT: Record<TDensity, number> = { default: 40, compact: 32 }

export const suiGridClasses = {
  borderRight: 'border-right',
  borderLeft: 'border-left',
  numericTabularNums: 'numeric-tabular-nums',
}
