/* eslint-disable settle/types-name-convention */
import type {} from '@mui/x-date-pickers/themeAugmentation'
import type {} from '@mui/x-data-grid-premium/themeAugmentation'
import { ComponentsOverrides, ComponentsProps, createTheme } from '@mui/material/styles'
import { gridClasses } from '@mui/x-data-grid-premium'
import { omit } from 'lodash'
import * as tokens from './tokens'
import * as switchTokens from './tokens/common/Switch'
import * as badgeTokens from './tokens/common/Badge'
import * as selectorTokens from './tokens/common/Selector'
import * as tooltipTokens from './tokens/common/Tooltip'
import * as inputTokens from './tokens/common/Input'
import * as inputOutlinedTokens from './tokens/common/InputOutlined'
import * as chipTokens from './tokens/common/Chip'
import { AutocompleteLoadingText } from './components/Autocomplete/components/AutocompleteLoadingText/AutocompleteLoadingText'
import { suiGridClasses } from './components/DataGrid/DataGrid.consts'

const Z_INDEX = {
  appBar: 1100,
  floatingActionPanel: 1130,
  businessDrawer: 1150,
  banner: 1200,
  dialog: 1300,
  drawer: 1300,
  snackbar: 11000,
  lineItemsDrawerHandle: 4,
} as const

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    tokens: tokens.ITokens
    zIndexes: typeof Z_INDEX
    // Contains selector you can use to specify custom styles when "obtrusive scrollbars"
    // are turned on the page. See the setScrollbarStrategy helper for more details:
    // https://github.com/team-settle/settle-web/blob/main/src/settle-ui/lib/scrollbarObtrusive/setScrollbarStrategy.ts
    withObtrusiveScrollbar: string
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ThemeOptions {
    tokens: Theme['tokens']
    zIndexes: typeof Z_INDEX
    withObtrusiveScrollbar: Theme['withObtrusiveScrollbar']
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    onSurfacePrimary: string
    onSurfaceSecondary: string
    onSurfaceTertiary: string
    linkPrimary: string
    brand: {
      qboPrimary: string
      xeroPrimary: string
      netSuiteDark: string
      finaloopPrimary: string
    }
  }

  interface TypeText {
    warning: string
  }

  interface PaletteOptions {
    onSurfacePrimary: string
    onSurfaceSecondary: string
    onSurfaceTertiary: string
    linkPrimary: string
    brand: {
      qboPrimary: string
      xeroPrimary: string
      netSuiteDark: string
      finaloopPrimary: string
    }
  }
}

declare module '@mui/material/styles/components' {
  interface Components {
    MuiDesktopDatePicker?: {
      defaultProps?: ComponentsProps['MuiDatePicker']
      styleOverrides?: ComponentsProps['MuiDatePicker']
      variants?: ComponentsProps['MuiDatePicker']
    }
    MuiStaticDatePicker?: {
      defaultProps?: ComponentsProps['MuiDatePicker']
      styleOverrides?: ComponentsProps['MuiDatePicker']
      variants?: ComponentsProps['MuiDatePicker']
    }
    MuiDataGrid?: {
      defaultProps?: ComponentsProps['MuiDataGrid']
      styleOverrides?: ComponentsOverrides['MuiDataGrid']
    }
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    numericTabularNums: React.CSSProperties
    inherit: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    numericTabularNums?: React.CSSProperties
    inherit: React.CSSProperties
  }

  interface BreakpointOverrides {
    xxl: true
    mobile: true
    tablet: true
    desktop: true
  }
}

declare module '@mui/material/Typography/Typography' {
  export interface TypographyPropsVariantOverrides {
    numericTabularNums: true
    inherit: true
  }
}

interface ISizeOverrides {
  small: true
  medium: true
  large: true
}

declare module '@mui/material/IconButton/IconButton' {
  export interface IconButtonPropsSizeOverrides {
    small: true
    medium: true
    large: true
    tiny: true
  }
}

declare module '@mui/material/InputBase/InputBase' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface InputBasePropsSizeOverrides extends ISizeOverrides {}
}

declare module '@mui/material/FormControl/FormControl' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface FormControlPropsSizeOverrides extends ISizeOverrides {}
}

const body2 = {
  fontSize: tokens.ThemeTypographyDesktopBody2FontSize,
  fontWeight: tokens.ThemeTypographyDesktopBody2FontWeight,
  letterSpacing: tokens.ThemeTypographyDesktopBody2LetterSpacing,
  lineHeight: tokens.ThemeTypographyDesktopBody2LineHeight,
}

export const classNames = {
  // A selector you can use to specify custom styles when "obtrusive scrollbars" are turned on the page.
  obtrusiveScrollbar: 'obtrusiveScrollbar',
} as const

const numericTabularNums = {
  ...body2,
  fontVariantNumeric: 'tabular-nums',
  letterSpacing: 0,
}

export const theme = createTheme({
  tokens,
  zIndexes: Z_INDEX,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
      mobile: 900,
      tablet: 1024,
      desktop: 1200,
    },
  },
  typography: {
    numericTabularNums,
    h1: {
      fontSize: tokens.ThemeTypographyDesktopH1FontSize,
      lineHeight: tokens.ThemeTypographyDesktopH1LineHeight,
      // TODO: https://linear.app/settle/issue/FRONT-5001/update-letterspacing-for-headings-in-design-tokens
      // letterSpacing: tokens.ThemeTypographyDesktopH1LetterSpacing,
      // fontWeight: tokens.ThemeTypographyDesktopH1FontWeight,
      letterSpacing: '-0.025em',
      fontWeight: 500,
    },
    h2: {
      fontSize: tokens.ThemeTypographyDesktopH2FontSize,
      lineHeight: tokens.ThemeTypographyDesktopH2LineHeight,
      // letterSpacing: tokens.ThemeTypographyDesktopH2LetterSpacing,
      // fontWeight: tokens.ThemeTypographyDesktopH2FontWeight,
      letterSpacing: '-0.025em',
      fontWeight: 500,
    },
    h3: {
      fontSize: tokens.ThemeTypographyDesktopH3FontSize,
      lineHeight: tokens.ThemeTypographyDesktopH3LineHeight,
      // letterSpacing: tokens.ThemeTypographyDesktopH3LetterSpacing,
      // fontWeight: tokens.ThemeTypographyDesktopH3FontWeight,
      letterSpacing: '-0.025em',
      fontWeight: 500,
    },
    h4: {
      fontSize: tokens.ThemeTypographyDesktopH4FontSize,
      lineHeight: tokens.ThemeTypographyDesktopH4LineHeight,
      // letterSpacing: tokens.ThemeTypographyDesktopH4LetterSpacing,
      // fontWeight: tokens.ThemeTypographyDesktopH4FontWeight,
      letterSpacing: '-0.025em',
      fontWeight: 500,
    },
    h5: {
      fontSize: tokens.ThemeTypographyDesktopH5FontSize,
      fontWeight: tokens.ThemeTypographyDesktopH5FontWeight,
      letterSpacing: tokens.ThemeTypographyDesktopH5LetterSpacing,
      lineHeight: tokens.ThemeTypographyDesktopH5LineHeight,
    },
    h6: {
      fontSize: tokens.ThemeTypographyDesktopH6FontSize,
      fontWeight: tokens.ThemeTypographyDesktopH6FontWeight,
      letterSpacing: tokens.ThemeTypographyDesktopH6LetterSpacing,
      lineHeight: tokens.ThemeTypographyDesktopH6LineHeight,
    },
    caption: {
      fontSize: tokens.ThemeTypographyDesktopCaptionFontSize,
      fontWeight: tokens.ThemeTypographyDesktopCaptionFontWeight,
      letterSpacing: tokens.ThemeTypographyDesktopCaptionLetterSpacing,
      lineHeight: tokens.ThemeTypographyDesktopCaptionLineHeight,
    },
    overline: {
      fontSize: tokens.ThemeTypographyDesktopOverlineFontSize,
      fontWeight: tokens.ThemeTypographyDesktopOverlineFontWeight,
      letterSpacing: tokens.ThemeTypographyDesktopOverlineLetterSpacing,
      lineHeight: tokens.ThemeTypographyDesktopOverlineLineHeight,
    },
    subtitle1: {
      fontSize: tokens.ThemeTypographyDesktopSubtitle1FontSize,
      fontWeight: tokens.ThemeTypographyDesktopSubtitle1FontWeight,
      letterSpacing: tokens.ThemeTypographyDesktopSubtitle1LetterSpacing,
      lineHeight: tokens.ThemeTypographyDesktopSubtitle1LineHeight,
    },
    subtitle2: {
      fontSize: tokens.ThemeTypographyDesktopSubtitle2FontSize,
      fontWeight: tokens.ThemeTypographyDesktopSubtitle2FontWeight,
      letterSpacing: tokens.ThemeTypographyDesktopSubtitle2LetterSpacing,
      lineHeight: tokens.ThemeTypographyDesktopSubtitle2LineHeight,
    },
    body1: {
      fontSize: tokens.ThemeTypographyDesktopBody1FontSize,
      fontWeight: tokens.ThemeTypographyDesktopBody1FontWeight,
      letterSpacing: tokens.ThemeTypographyDesktopBody1LetterSpacing,
      lineHeight: tokens.ThemeTypographyDesktopBody1LineHeight,
    },
    inherit: {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
    },
    body2,
    fontFamily: [
      'Inter',
      'SF Pro Display',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Open Sans',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),
  },
  palette: {
    mode: 'light',
    divider: tokens.ThemeColorCommonSeparator,
    error: {
      main: tokens.ThemeColorCommonError,
    },
    primary: {
      main: tokens.ThemeColorCommonPrimary,
    },
    secondary: {
      main: tokens.ThemeColorCommonSecondary,
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
    text: {
      primary: tokens.ThemeColorCommonOnSurfacePrimary,
      secondary: tokens.ThemeColorCommonOnSurfaceSecondary,
      warning: tokens.ThemeColorCommonWarning,
    },
    linkPrimary: tokens.ThemeColorCommonOnPrimaryLink,
    onSurfacePrimary: tokens.ThemeColorCommonOnSurfacePrimary,
    onSurfaceSecondary: tokens.ThemeColorCommonOnSurfaceSecondary,
    onSurfaceTertiary: tokens.ThemeColorCommonOnSurfaceTertiary,
    brand: {
      netSuiteDark: '#414141',
      xeroPrimary: '#1DB3D3',
      qboPrimary: '#2CA01C',
      finaloopPrimary: '#C25F31',
    },
  },
  components: {
    MuiListSubheader: {
      styleOverrides: {
        root: {
          lineHeight: '40px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          minWidth: 'auto',
          textTransform: 'initial',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          color: tokens.ThemeColorCommonOnPrimary,
        },
        outlined: {
          color: tokens.ThemeColorCommonPrimary,
        },
        containedSecondary: {
          backgroundColor: tokens.ThemeColorCommonDestructive,
          borderColor: tokens.ThemeColorCommonDestructive,
          color: '#fff',
          '&:hover': {
            backgroundColor: tokens.ThemeColorCommonDestructive,
            borderColor: tokens.ThemeColorCommonDestructive,
            color: '#fff',
          },
        },
        outlinedSecondary: {
          borderColor: tokens.ThemeColorCommonDestructive,
          color: tokens.ThemeColorCommonDestructive,
          '&:hover': {
            backgroundColor: tokens.ThemeColorCommonDestructive,
            borderColor: tokens.ThemeColorCommonDestructive,
            color: '#fff',
          },
        },
        outlinedPrimary: {
          borderColor: tokens.ThemeColorCommonPrimary,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        variant: 'body2',
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderRadius: inputTokens.InputBaseBorderRadiusMedium,
        },
        sizeSmall: {
          borderRadius: inputTokens.InputBaseBorderRadiusSmall,
        },
        input: {
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&::[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: inputOutlinedTokens.InputOutlinedColorDefaultOutline,
          borderWidth: inputOutlinedTokens.InputOutlinedBorderWidthDefault,
        },
        root: {
          backgroundColor: inputOutlinedTokens.InputOutlinedColorDefaultBackground,
          letterSpacing: inputTokens.InputBaseTypographyTextLetterSpacing,
          lineHeight: inputTokens.InputBaseTypographyTextLineHeight,
          fontSize: inputTokens.InputBaseTypographyTextFontSize,

          '&.Mui-focused': {
            backgroundColor: inputOutlinedTokens.InputOutlinedColorFocusedBackground,
            color: inputOutlinedTokens.InputOutlinedColorFocusedText,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: inputOutlinedTokens.InputOutlinedColorFocusedOutline,
            borderWidth: inputOutlinedTokens.InputOutlinedBorderWidthFocused,
          },
          '&.Mui-disabled': {
            backgroundColor: inputOutlinedTokens.InputOutlinedColorDisabledBackground,
            color: inputOutlinedTokens.InputOutlinedColorDisabledText,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: inputOutlinedTokens.InputOutlinedColorDisabledOutline,
            borderWidth: inputOutlinedTokens.InputOutlinedBorderWidthDisabled,
          },
          '&.Mui-error': {
            backgroundColor: inputOutlinedTokens.InputOutlinedColorErrorBackground,
            color: inputOutlinedTokens.InputOutlinedColorErrorText,
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: inputOutlinedTokens.InputOutlinedColorErrorOutline,
            borderWidth: inputOutlinedTokens.InputOutlinedBorderWidthError,
          },
          '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: inputOutlinedTokens.InputOutlinedColorErrorFocusedOutline,
            borderWidth: inputOutlinedTokens.InputOutlinedBorderWidthErrorFocused,
          },
          '&:hover:not(.Mui-focused):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            borderColor: inputOutlinedTokens.InputOutlinedColorHoveredOutline,
            borderWidth: inputOutlinedTokens.InputOutlinedBorderWidthHovered,
          },
        },
        input: {
          // This is a "temporary" solution. We will move these
          // hardcoded values to the design tokens in the future.
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0,0,0,.38)',
          },
        },
        inputSizeSmall: {
          paddingTop: '10px',
          paddingBottom: '10px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          letterSpacing: inputTokens.InputBaseTypographyTextLetterSpacing,
          lineHeight: inputTokens.InputBaseTypographyTextLineHeight,
          fontSize: inputTokens.InputBaseTypographyTextFontSize,
          color: inputOutlinedTokens.InputOutlinedColorIdleLabel,

          '&.Mui-error': {
            color: inputOutlinedTokens.InputOutlinedColorErrorLabel,
          },
          '&.Mui-focused:not(.Mui-error)': {
            color: inputOutlinedTokens.InputOutlinedColorFocusedLabel,
          },
          '&.Mui-disabled:not(.Mui-error)': {
            color: inputOutlinedTokens.InputOutlinedColorDisabledLabel,
          },
        },
        outlined: {
          color: inputOutlinedTokens.InputOutlinedColorDefaultLabel,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          letterSpacing: inputTokens.InputBaseTypographyHelperLetterSpacing,
          lineHeight: inputTokens.InputBaseTypographyHelperLineHeight,
          fontSize: inputTokens.InputBaseTypographyHelperFontSize,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '42px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: '16px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: tokens.ThemeColorCommonSeparator,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
        gutters: ({ theme }) => ({
          padding: theme.spacing(1, 1.5),
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 500,
        enterTouchDelay: 0,
        placement: 'bottom-start',
        arrow: false,
      },
      styleOverrides: {
        tooltip: {
          display: 'flex',
          flexDirection: 'column',
          maxWidth: tooltipTokens.TooltipSizeMaxWidthDesktop,
          minHeight: tooltipTokens.TooltipSizeMinHeightDesktop,
          marginLeft: 0,
          marginRight: 0,
          paddingTop: tooltipTokens.TooltipSpacingDesktopVertical,
          paddingBottom: tooltipTokens.TooltipSpacingDesktopVertical,
          paddingLeft: tooltipTokens.TooltipSpacingDesktopHorizontal,
          paddingRight: tooltipTokens.TooltipSpacingDesktopHorizontal,
          lineHeight: tooltipTokens.TooltipTypographyDesktopLineHeight,
          fontWeight: tooltipTokens.TooltipTypographyDesktopFontWeight,
          fontSize: tooltipTokens.TooltipTypographyDesktopFontSize,
          color: tooltipTokens.TooltipColorDefaultText,
          backgroundColor: tooltipTokens.TooltipColorDefaultBackground,
          borderRadius: tooltipTokens.TooltipBorderRadiusDesktop,
          borderWidth: tooltipTokens.TooltipBorderWidthDefault,
          boxShadow: `${tooltipTokens.TooltipShadowDesktopX} ${tooltipTokens.TooltipShadowDesktopY} ${tooltipTokens.TooltipShadowDesktopBlur} ${tooltipTokens.TooltipShadowDesktopSpread} ${tooltipTokens.TooltipShadowDesktopColor}`,
          '&&&': {
            marginTop: tooltipTokens.TooltipSpacingDesktopMarginTop,
          },
          '@media (max-width: 900px)': {
            maxWidth: tooltipTokens.TooltipSizeMaxWidthMobile,
            minHeight: tooltipTokens.TooltipSizeMinHeightMobile,
            paddingTop: tooltipTokens.TooltipSpacingMobileVertical,
            paddingBottom: tooltipTokens.TooltipSpacingMobileVertical,
            paddingLeft: tooltipTokens.TooltipSpacingMobileHorizontal,
            paddingRight: tooltipTokens.TooltipSpacingMobileHorizontal,
            borderRadius: tooltipTokens.TooltipBorderRadiusMobile,
            '&&&': {
              marginTop: tooltipTokens.TooltipSpacingMobileMarginTop,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: `
          0px 5px 5px -3px rgba(0,0,0,0.2),
          0px 8px 10px 1px rgba(0,0,0,0.14),
          0px 3px 14px 2px rgba(0,0,0,0.12)`,
        },
        noOptions: {
          fontSize: tokens.ThemeTypographyDesktopBody2FontSize,
          fontWeight: tokens.ThemeTypographyDesktopBody2FontWeight,
          letterSpacing: tokens.ThemeTypographyDesktopBody2LetterSpacing,
          lineHeight: tokens.ThemeTypographyDesktopBody2LineHeight,
          textAlign: 'center',
        },
        inputRoot: {
          paddingTop: '0!important',
          paddingBottom: '0!important',
          '&.MuiInputBase-sizeSmall': {
            paddingLeft: 0,
          },
        },
      },
      defaultProps: {
        ChipProps: {
          size: 'small',
          color: 'default',
          variant: 'filled',
        },
        selectOnFocus: false,
        loadingText: <AutocompleteLoadingText />,
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: selectorTokens.SelectorColorUnselectedDefaultBody,
          '&.Mui-checked': {
            color: selectorTokens.SelectorColorSelectedDefaultBody,
          },
          '&.MuiCheckbox-indeterminate': {
            color: selectorTokens.SelectorColorIndeterminateDefaultBody,
          },
          '&.Mui-disabled': {
            color: selectorTokens.SelectorColorUnselectedDisabledBody,
          },
        },
      },
    },
    MuiChip: {
      defaultProps: {
        sx: {
          color: chipTokens.ChipColorDefaultText,
          background: chipTokens.ChipColorDefaultBackground,
        },
      },
      styleOverrides: {
        sizeSmall: {
          height: chipTokens.ChipSizeDesktopSmall,
        },
        labelSmall: ({ theme }) => ({
          fontSize: theme.spacing(1.75),
          lineHeight: theme.spacing(2.5),
        }),
        outlinedSecondary: {
          background: tokens.ThemeColorCommonPrimaryContainer,
        },
        deleteIcon: {
          color: tokens.ThemeColorCommonOnSurfaceTertiary,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: Z_INDEX.appBar,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
          '&:first-of-type': {
            paddingTop: '20px',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          ':has(> div[style*="opacity: 0"])': {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: badgeTokens.BadgeColorPrimaryBackground,
          color: badgeTokens.BadgeColorPrimaryText,
        },
        colorSecondary: {
          background: badgeTokens.BadgeColorSecondaryBackground,
          color: badgeTokens.BadgeColorSecondaryText,
        },
        dot: {
          minWidth: badgeTokens.BadgeSizeNumberOffDesktop,
          '@media (max-width: 900px)': {
            minWidth: badgeTokens.BadgeSizeNumberOffMobile,
          },
        },
        standard: {
          minWidth: badgeTokens.BadgeSizeNumberOnDesktop,
          '@media (max-width: 900px)': {
            minWidth: badgeTokens.BadgeSizeNumberOnMobile,
          },
        },
        colorWarning: {
          background: badgeTokens.BadgeColorTertiaryBackground,
          color: badgeTokens.BadgeColorTertiaryText,
        },
        colorInfo: {
          background: badgeTokens.BadgeColorBlankBackground,
          color: badgeTokens.BadgeColorBlankText,
        },
      },
    },
    MuiDesktopDatePicker: {
      defaultProps: {
        minDate: new Date('2018-01-01'),
      },
    },
    MuiStaticDatePicker: {
      defaultProps: {
        minDate: new Date('2018-01-01'),
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          background: tokens.ThemeColorCommonSurface,
          border: `1px solid ${tokens.ThemeColorCommonOutline}`,
          borderRadius: '5px',
        },
      },
      defaultProps: {
        elevation: 7,
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
        focusVisibleClassName: '.Mui-focusVisible',
      },
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          width: switchTokens.ToggleSizeAppleDesktopBodyWidth,
          height: switchTokens.ToggleSizeAppleDesktopBodyHeight,
          padding: 0,
          margin: '2px 7px',
          ...(ownerState?.size === 'medium' && {
            width: switchTokens.ToggleSizeAppleDesktopBodyWidth,
            height: switchTokens.ToggleSizeAppleDesktopBodyHeight,
            margin: '4px 11px',
          }),
          '& .MuiSwitch-switchBase': {
            padding: 0,
            color: switchTokens.ToggleColorUnselectedDefaultKnob,
            top: `calc(50% - ${switchTokens.ToggleSizeAppleDesktopKnob}/2)`,
            left: 2,
            transitionDuration: '300ms',
            ...(ownerState?.size === 'medium' && {
              top: `calc(50% - ${switchTokens.ToggleSizeAppleDesktopKnob}/2)`,
            }),
            '&.Mui-checked': {
              color: switchTokens.ToggleColorSelectedDefaultKnob,
              transform: `translateX(calc(${switchTokens.ToggleSizeAppleDesktopBodyWidth} - ${switchTokens.ToggleSizeAppleDesktopKnob} - 4px))`,
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: switchTokens.ToggleColorSelectedDefaultBody,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: switchTokens.ToggleColorSelectedDisabledBody,
              },
              '&.Mui-disabled': {
                color: switchTokens.ToggleColorUnselectedDisabledKnob,
              },
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: switchTokens.ToggleColorUnselectedDisabledBody,
            },
            '&.Mui-disabled': {
              opacity: 1,
              color: switchTokens.ToggleColorSelectedDisabledKnob,
            },
          },
          '& .MuiSwitch-thumb': {
            width: switchTokens.ToggleSizeAppleDesktopKnob,
            height: switchTokens.ToggleSizeAppleDesktopKnob,
            borderRadius: switchTokens.ToggleBorderRadiusAppleDesktopKnob,
            boxShadow: 'none',
            ...(ownerState?.size === 'medium' && {
              width: switchTokens.ToggleSizeAppleDesktopKnob,
              height: switchTokens.ToggleSizeAppleDesktopKnob,
              borderRadius: switchTokens.ToggleBorderRadiusAppleDesktopKnob,
            }),
          },
          '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: switchTokens.ToggleColorUnselectedDefaultBody,
            borderRadius: switchTokens.ToggleBorderRadiusAppleDesktopBody,
            transition: theme.transitions.create(['background-color'], {
              duration: 500,
            }),
            ...(ownerState?.size === 'medium' && {
              borderRadius: switchTokens.ToggleBorderRadiusAppleDesktopBody,
            }),
          },
        }),
      },
    },
    // TODO: FRONT-4539 fix bug due to react-transition-group bug
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&[style*="opacity: 0"]': {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          padding: 0,
          boxShadow: 'none',
          borderRadius: ownerState.square ? 0 : theme.spacing(0.5),
          border: `1px solid ${theme.tokens.ThemeColorCommonSeparator}`,
          '&:before': {
            display: 'none',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1, 3),
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: theme.spacing(1.5, 0),
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            order: -1,
            marginRight: theme.spacing(3),
            '&.Mui-expanded': {
              transform: 'rotate(90deg)',
            },
          },
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0, 0, 1),
          borderTop: `1px solid ${theme.tokens.ThemeColorCommonSeparator}`,
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          [`&.${suiGridClasses.borderLeft}`]: {
            borderLeft: `1px solid var(--DataGrid-rowBorderColor)`,
          },
          [`&.${suiGridClasses.borderRight}`]: {
            borderRight: `1px solid var(--DataGrid-rowBorderColor)`,
          },
          [`& .${gridClasses.columnSeparator}`]: {
            [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
              display: 'none',
            },
          },
        },
        cell: {
          [`&.${gridClasses.cell}:focus`]: {
            outline: 'none',
          },
          [`&.${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`&.${suiGridClasses.borderLeft}`]: {
            borderLeft: `1px solid var(--DataGrid-rowBorderColor)`,
          },
          [`&.${suiGridClasses.borderRight}`]: {
            borderRight: `1px solid var(--DataGrid-rowBorderColor)`,
          },
          [`&.${suiGridClasses.numericTabularNums}`]: {
            ...omit(numericTabularNums, 'lineHeight'),
          },
        },
        footerContainer: {
          minHeight: '40px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          color: tokens.ThemeColorCommonOnSurfaceSecondary,
        },
        selectLabel: {
          color: tokens.ThemeColorCommonOnSurfaceSecondary,
        },
        select: {
          color: tokens.ThemeColorCommonOnSurfaceSecondary,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: tokens.ThemeColorCommonBackground,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: 500,
          letterSpacing: '0.25px',
          color: tokens.ThemeColorCommonOnSurfaceSecondary,
          '&.Mui-selected': {
            backgroundColor: tokens.ThemeColorCommonSecondaryContainer,
            color: tokens.ThemeColorCommonSecondary,
          },
          '&.Mui-disabled': {
            border: 'none',
          },
          '&:not(.Mui-selected) + &:not(.Mui-selected):before': {
            content: '""',
            position: 'absolute',
            left: 0,
            borderLeft: `1px solid ${tokens.ThemeColorCommonSeparator}`,
          },
          '&:hover:before, &:hover + &:not(.Mui-selected):before': {
            display: 'none',
          },
        },
        sizeSmall: ({ theme }) => ({
          minWidth: '32px',
          padding: theme.spacing(0.5, 1),
          borderRadius: '4px',
          '&:not(.Mui-selected) + &:not(.Mui-selected):before': {
            top: '6px',
            bottom: '6px',
          },
        }),
        sizeMedium: ({ theme }) => ({
          minWidth: '48px',
          padding: theme.spacing(1),
          borderRadius: '5px',
          '&:not(.Mui-selected) + &:not(.Mui-selected):before': {
            top: '8px',
            bottom: '8px',
          },
        }),
      },
    },
  },
  withObtrusiveScrollbar: ` .${classNames.obtrusiveScrollbar} &`,
})

export const mobileCutoffPreviewStyles = {
  [theme.breakpoints.down('mobile')]: {
    maxHeight: '360px',
    mask: 'conic-gradient(from -45deg at bottom, #000, #000 0 90deg, #0000 90deg) 25%/10px 100%',
  },
} as const

export const prideMonthTheme = createTheme(theme, {
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#F7F6F6',
        },
        bar: {
          background:
            'linear-gradient(90deg, #F27B79 0%, #FFB18F 20%, #FFEF91 40%, #82BF92 60%, #778AD1 80%, #C39CD9 99%)',
        },
      },
    },
  },
})
